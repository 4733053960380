import React from 'react';
import Icon, {
  LockOutlined,
  UserOutlined,
  UserAddOutlined,
  LoginOutlined,
  LogoutOutlined,
  LockFilled,
  SettingOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  KeyOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlusOutlined,
  OrderedListOutlined,
  EditOutlined,
  SaveOutlined,
  ArrowLeftOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  WarningOutlined,
  MenuOutlined,
  DashboardOutlined,
  FormOutlined,
  TableOutlined,
  ProfileOutlined,
  TeamOutlined,
  RightOutlined,
  SearchOutlined,
  CopyOutlined,
  QuestionCircleOutlined,
  BellOutlined,
  WalletOutlined,
  CaretDownOutlined,
  DownOutlined,
  PrinterOutlined,
  HighlightOutlined,
  AuditOutlined,
  DownloadOutlined,
  QrcodeOutlined,
  FilterFilled,
  MoneyCollectOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  AppstoreOutlined,
  HomeOutlined,
  BulbOutlined,
  EllipsisOutlined,
  ArrowRightOutlined,
  CaretUpOutlined,
} from '@ant-design/icons';

import {
  AttentionIcon,
  CheckIcon,
  EuroRefreshIcon,
  LockIcon,
  MessageSent,
  PinRecover,
  StatusDot,
  DistributorsIcon,
} from './IconList';
import { IIcon } from './Icon.types';

const CustomIcon = (props: IIcon): JSX.Element => {
  const { type, className } = props;
  const icons = {
    lock: LockOutlined,
    'lock-filled': LockFilled,
    user: UserOutlined,
    users: TeamOutlined,
    dashboard: DashboardOutlined,
    distributors: DistributorsIcon,
    partner: FormOutlined,
    pos: TableOutlined,
    cashier: ProfileOutlined,
    addUser: UserAddOutlined,
    login: LoginOutlined,
    logout: LogoutOutlined,
    setting: SettingOutlined,
    eye: EyeOutlined,
    eyeInvisible: EyeInvisibleOutlined,
    key: KeyOutlined,
    menu: MenuOutlined,
    showMenu: MenuUnfoldOutlined,
    hideMenu: MenuFoldOutlined,
    plus: PlusOutlined,
    'ordered-list': OrderedListOutlined,
    edit: EditOutlined,
    save: SaveOutlined,
    'arrow-left': ArrowLeftOutlined,
    'arrow-right': RightOutlined,
    'arrow-right-long': ArrowRightOutlined,
    'check-circle': CheckCircleFilled,
    print: PrinterOutlined,
    'close-circle': CloseCircleOutlined,
    warning: WarningOutlined,
    'wi-check': CheckIcon,
    'wi-attention': AttentionIcon,
    'wi-euro-refresh': EuroRefreshIcon,
    'wi-lock': LockIcon,
    'wi-send': MessageSent,
    'wi-pin-recover': PinRecover,
    search: SearchOutlined,
    'status-dot': StatusDot,
    copy: CopyOutlined,
    question: QuestionCircleOutlined,
    bell: BellOutlined,
    walletOutlined: WalletOutlined,
    'arrow-down-fill': CaretDownOutlined,
    'arrow-up-fill': CaretUpOutlined,
    'arrow-down': DownOutlined,
    highlight: HighlightOutlined,
    audit: AuditOutlined,
    download: DownloadOutlined,
    qr: QrcodeOutlined,
    qr_another: AppstoreOutlined,
    house: HomeOutlined,
    filter: FilterFilled,
    money: MoneyCollectOutlined,
    tutorial: InfoCircleOutlined,
    'info-circle': ExclamationCircleOutlined,
    close: CloseOutlined,
    bulbOutlined: BulbOutlined,
    ellipsisOutlined: EllipsisOutlined,
  };

  return <Icon component={icons[type]} className={className} />;
};

export default CustomIcon;
